input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    filter: alpha(opacity=0);
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: inherit;
}

#sendFile {
    cursor: inherit;
}

a>i #sendFile {
    cursor: inherit;
}

a:hover #sendFile {
    color: red;
}
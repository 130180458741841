.microphone {
    /* margin-top: 50px;
    margin-bottom: 50px;*/
    border-radius: 100%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    border: 4px solid rgba(0, 0, 0, 0.3);
    position: absolute;
    /* position: relative;*/
    overflow: hidden;
    /* margin-top: 10px;*/
    /*margin-bottom: 5px;*/
}

.microphone.active {
    background-color: black;
    border-color: black;
}

.microphone.active i {
    color: red;
}

.microphone i {
    color: gray;
    font-size: 30px;
    position: relative;
    z-index: 1;
}

.microphone .decibel {
    display: none;
    background-color: white;
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 0px;
}

.microphone.active .decibel {
    display: block;
}

.microphoneStop {
    /* margin-top: 50px;
    margin-bottom: 50px;*/
    border-radius: 100%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0px auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.3);
    border: 4px solid rgba(0, 0, 0, 0.3);
    position: absolute;
    /* position: relative;*/
    overflow: hidden;
    /* margin-top: 10px;*/
    /*margin-bottom: 5px;*/
}

.microphoneStop.active {
    background-color: black;
    border-color: black;
}

.microphoneStop.active i {
    color: red;
}

.microphoneStop i {
    color: gray;
    font-size: 30px;
    position: relative;
    z-index: 1;
}

.microphoneStop .decibel {
    display: none;
    background-color: white;
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    height: 0px;
}

.microphoneStop.active .decibel {
    display: block;
}

.noactivem{
    display: none;
}